@font-face {
  font-family: 'OpenSans';
  src: local('OpenSans Light'), local('OpenSans-Light'), url('OpenSans-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'OpenSans';
  src: local('OpenSans Regular'), local('OpenSans-Regular'), url('OpenSans-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'OpenSans';
  src: local('OpenSans Medium'), local('OpenSans-Medium'), url('OpenSans-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: 'OpenSans';
  src: local('OpenSans Bold'), local('OpenSans-Bold'), url('OpenSans-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}