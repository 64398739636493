html {
  --roboto: "Roboto", sans-serif;
  --arial: "Arial", sans-serif;
  --gilroy: "Gilroy", sans-serif;
  --openSans: "OpenSans", sans-serif;

  --backgroundColor: #FFFFFF;
  --blackFontColor: #222F3E;
  --grayFontColor: #576574;
  --cancelFontColor: #007F8F;
  --lightBackgroundColor: #F8F8F8;

  --submitButtonColor: #D9EDF7;
  --submitButtonBorderColor: #CAEBF1;
  --submitButtonFontColor: #007F8F;

  --inputLabelColor: #9CA3AF;
  --inputBorderColor: rgba(7, 101, 87, 0.7);

  --errorColor: #FA6464;

  --borderColor: #EAEAEA;
  --separatorColor: #E9E9E9;
}