@font-face {
  font-family: 'Arial';
  src: local('Arial Regular'), local('Arial-Regular'), url('Arial-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Arial';
  src: local('Arial Bold'), local('Arial-Bold'), url('Arial-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}