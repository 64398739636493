@font-face {
  font-family: 'Roboto';
  src: local('Roboto Light'), local('Roboto-Light'), url('Roboto-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'Roboto';
  src: local('Roboto Regular'), local('Roboto-Regular'), url('Roboto-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Roboto';
  src: local('Roboto Medium'), local('Roboto-Medium'), url('Roboto-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'Roboto';
  src: local('Roboto Bold'), local('Roboto-Bold'), url('Roboto-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}