.logo-wrapper {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  width: 45%;
  .bank-id-icon__width-64 {
    display: none;
  }
  &--title {
    font-family: var(--arial);
    font-size: 14px;
    margin-top: 30px;
    color: var(--grayFontColor);
  }
  @media (max-width: 599px) {
    width: calc(100% + 24px);
    order: 0;
    padding-bottom: 32px;
    box-shadow: 0 2px 4px rgba(184, 184, 184, 0.2);
    &--title {
      margin-top: 24px;
    }
    .bank-id-icon__width-64 {
      display: block;
    }
    .bank-id-icon__width-100 {
      display: none;
    }
  }
}