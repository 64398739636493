.input-field {
  width: 100%;
  .input-label {
    font-family: var(--gilroy);
    color: var(--inputLabelColor);
    font-size: 14px;
    transform: translate(8px, 15px);
    background-color: var(--backgroundColor);
    padding: 0 6px;
    &.input-label {
      &__focused {
        color: var(--inputLabelColor);
        transform: translate(18px, -4px) scale(0.85)
      }
      &__active {
        transform: translate(18px, -4px) scale(0.85)
      }
      &__error {
        color: var(--errorColor);
      }
    }
  }
  .custom-input {
    font-family: var(--gilroy);
    color: var(--blackFontColor);
    font-size: 14px;
    background-color: var(--backgroundColor)!important;
    height: 44px;
    border: 1px solid var(--borderColor);
    border-radius: 8px;
    &__input {
      padding: 12px 14px;
      &:disabled {
        color: rgba(255,255,255,0.72);
      }
    }
    &.custom-input {
      &__focused {
        border: 1px solid var(--inputBorderColor);
      }
      &__error {
        border: 1px solid var(--errorColor);
      }
    }
    &:after, &:before {
      display: none;
    }
  }
  .error-icon {
    cursor: pointer;
  }
}