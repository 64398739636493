.default_button_wrapper {
  .default_button {
    text-align: center;
    display: inline-flex;
    white-space: nowrap;
    -webkit-transition: all 0.3s linear;
    -moz-transition: all 0.3s linear;
    -o-transition: all 0.3s linear;
    transition: all 0.3s linear;
    box-shadow: none;
    &:disabled {
      opacity: 0.5;
      box-shadow: none;
    }
    &.default_button_contained {
      color: var(--submitButtonFontColor);
      background: var(--submitButtonColor);
      border: 1px solid var(--submitButtonBorderColor);
      box-sizing: border-box;
      border-radius: 4px;
      font-family: var(--arial);
      font-weight: 700;
      font-size: 12px;
      text-transform: uppercase;
      width: 100%;
      height: 44px;
    }
  }
}