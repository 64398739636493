body{
  overflow: auto;
  box-sizing: border-box;
  background: var(--backgroundColor);
  color: var(--blackFontColor);
}

.text-hover {
  -webkit-transition: all ease .3s;
  -moz-transition: all ease .3s;
  -o-transition: all ease .3s;
  transition: all ease .3s;
  cursor: pointer;
  &:hover{
    opacity: 0.5;
  }
}

.no-user-select {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.content-wrapper {
  margin: 0 auto;
  max-width: 620px;
  max-height: 410px;
  width: 100%;
  height: 100vh;
  background: var(--backgroundColor);
  border: 1px solid var(--borderColor);
  box-sizing: border-box;
  box-shadow: 0 4px 6px rgba(184, 184, 184, 0.08);
  border-radius: 4px;
  @media (max-width: 599px) {
    max-height: 571px;
  }
}

.app-header {
  box-shadow: 0 2px 4px rgba(184, 184, 184, 0.2);
  border-radius: 4px 4px 0 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 67px;
  &__title {
    font-family: var(--openSans);
    font-weight: 600;
    font-size: 20px;
  }
  @media (max-width: 599px) {
    box-shadow: none;
    &__title {
      font-size: 18px;
    }
  }
}

.cancel-btn {
  color: var(--cancelFontColor);
  font-size: 14px;
  line-height: 16px;
  font-family: var(--roboto);
  -webkit-transition: all ease .3s;
  -moz-transition: all ease .3s;
  -o-transition: all ease .3s;
  transition: all ease .3s;
  cursor: pointer;
  &:hover{
    opacity: 0.75;
  }
}

.mr-11 {
  margin-right: 11px;
}

.text-wrapper {
  width: calc(100% - 35px);
}
