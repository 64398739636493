.message_popper {
  .message_tooltip {
    background-color: #29323C;
    font-size: 10px;
    word-break: break-word;
    font-family: "Inter", sans-serif;
  }
  .error_message_tooltip {
    background-color: #ff0000;
    font-size: 10px;
    font-family: "Inter", sans-serif;
  }
}