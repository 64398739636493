.choose-identification-method {
  box-sizing: border-box;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 32px 24px;
  @media (max-width: 599px) {
    flex-flow: column;
    padding: 32px 12px 24px;
  }
  &__methods {
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    width: 55%;
    border-right: 1px solid var(--separatorColor);
    padding: 8px 24px 8px 0;
    min-height: 250px;
    box-sizing: border-box;
    @media (max-width: 599px) {
      width: 100%;
      order: 1;
      border-right: none;
      padding: 24px 0 0 0;
    }
  }
  &__method {
    margin: 8px 0;
    padding: 3px 3px 3px 10px;
    width: 100%;
    background: var(--lightBackgroundColor);
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    &--info {
      display: flex;
      align-items: center;
      .method-name {
        font-family: var(--roboto);
        font-size: 14px;
        color: var(--blackFontColor);
        margin-left: 14px;
      }
    }
    @media (max-width: 599px) {
      &--info {
        .method-name {
          font-size: 13px;
        }
      }
    }
  }
}