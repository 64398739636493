.bank-id-with-qr-code {
  box-sizing: border-box;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 32px 24px;
  @media (max-width: 599px) {
    flex-flow: column;
    padding: 32px 12px 24px;
  }
  &__wrapper {
    width: 55%;
    border-left: 1px solid var(--separatorColor);
    padding-left: 24px;
    display: flex;
    flex-flow: column;
    min-height: 250px;
    @media (max-width: 599px) {
      align-items: center;
      width: 100%;
      border-left: none;
      padding: 24px 0 0 0;
    }
  }
  &__text {
    font-family: var(--arial);
    font-size: 14px;
    line-height: 20px;
    color: var(--grayFontColor);
    margin-bottom: 16px;
  }
  &__qr-code {
    width: 128px;
    height: 128px;
    margin: 24px auto 22px;
  }
  &__status-text {
    display: flex;
    justify-content: center;
    font-family: var(--arial);
    font-size: 14px;
    line-height: 20px;
    color: var(--grayFontColor);
    margin: auto 0 16px;
  }
  &__buttons {
    margin-top: auto;
    display: flex;
    flex-flow: column;
    align-items: center;
    .default_button_wrapper {
      width: 100%;
    }
  }
  .cancel-btn {
    margin-top: 18px;
    text-align: left;
  }
}