.loading {
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  .dot {
    border-radius: 50%;
    width: 8px;
    height: 8px;
    background-color: #ECF6FB;
    margin: 0 2px;
    &:first-of-type {
      animation: first-dot 5s steps(1, end) infinite;
    }
    &:nth-of-type(2) {
      animation: second-dot 5s steps(1, end) infinite;
    }
    &:nth-of-type(3) {
      animation: third-dot 5s steps(1, end) infinite;
    }
    &:nth-of-type(4) {
      animation: fourth-dot 5s steps(1, end) infinite;
    }
    &:last-of-type {
      animation: last-dot 5s steps(1, end) infinite;
    }
  }
}

@keyframes first-dot {
  0% {
      background-color: #007F8F;
  }
}
@keyframes second-dot {
  20% {
      background-color: #007F8F;
  }
}
@keyframes third-dot {
  40% {
      background-color: #007F8F;
  }
}
@keyframes fourth-dot {
  60% {
      background-color: #007F8F;
  }
}
@keyframes last-dot {
  80% {
      background-color: #007F8F;
  }
}